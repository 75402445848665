import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const AdminHeader = ({title, redirect, btnName, btnUrl, btnName2, btnUrl2})=> {
  const navigate = useNavigate();
  const arrowStyle = {
    color: "white",
    padding: "0 2px 0 5px",
    cursor: 'pointer',
    fontSize: '20px'
  }
  return (
    <div className='d-flex justify-content-between align-items-center bg-dark mb-4 Mon_font'>
      <div className='d-flex justify-content-start align-items-center gap-4 p-3 Mon_font'>
          <i className="fa-solid fa-arrow-left" style={arrowStyle} onClick={()=>{navigate(redirect)}}></i>
          <h4 className='m-0 text-white Mon_font'>{title}</h4>
      </div>
      <div className='p-2 d-flex gap-2'>
        {btnName && <div>
          <button className='btn btn-warning btn-sm' onClick={()=>{navigate(btnUrl)}}>{btnName}</button>
        </div>}
        {btnName2 && <div>
          <button className='btn btn-warning btn-sm' onClick={()=>{navigate(btnUrl2)}}>{btnName2}</button>
        </div>}
      </div>
    </div>
  )
}

export default AdminHeader