import React from 'react'
import google from '../../images/LandingPage/google.png'
import facebook from '../../images/LandingPage/facebook.png'
import tripadvisor from '../../images/LandingPage/tripadvisor.png'
import expedia from '../../images/LandingPage/expedia.png'
import viator from '../../images/LandingPage/viator.png'
import makemytrip from '../../images/LandingPage/makemytrip.png'
import msme from '../../images/LandingPage/msme.png'
import '../../Style/LandingPage/WhyTrustUs.css'

const WhyTrustUS = () => {
    return (
        <React.Fragment>
            <h2 className='fw-bolder m-0 text-center'>Why Tripgix?</h2>
            <div className='hr-line mb-3'></div>
            <div className="Mon_font container-fluid why-trust-us">
                <div className="review-container">
                    <div className="google-logo">
                        <img src={google} alt="" />
                        <div className='ms-lg-3 ms-2'>
                            <h5>4.7</h5>
                            <p>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-solid fa-star'></i>
                                <i className="fa-solid fa-star-half-stroke"></i>
                            </p>
                            <p>Google Reviews</p>
                        </div>
                    </div>
                    <div className="facebook-logo">
                        <img src={facebook} alt="" />
                        <div className='ms-lg-3 ms-2'>
                            <h5>4.7</h5>
                            <p>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-solid fa-star'></i>
                                <i className="fa-solid fa-star-half-stroke"></i>
                            </p>
                            <p>Facebook Reviews</p>
                        </div>
                    </div>
                    <div className="tripadvisor-logo">
                        <img src={tripadvisor} alt="" />
                        <div className='ms-lg-3 ms-2'>
                            <h5>5.0</h5>
                            <p>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-solid fa-star'></i>
                                <i className='fa-solid fa-star'></i>
                            </p>
                            <p>Tripadvisor Reviews</p>
                        </div>
                    </div>
                </div>

                <div className="certified-container">
                    <div className='text-center d-block'>
                        <h5>Certified & Recognised by</h5>
                    </div>
                    <div className="certified-logo-container">
                        <div className="certify-logo">
                            <img src={expedia} alt="" />
                        </div>
                        <div className="certify-logo">
                            <img src={viator} alt="" />
                        </div>
                        <div className="certify-logo">
                            <img src={makemytrip} alt="" />
                        </div>
                        <div className="certify-logo">
                            <img src={msme} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default WhyTrustUS
