import React, { useRef } from 'react'
import '../../Style/Slider/TripSlider.css'
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';

const BlogSlider = ({ blogs, navigatation }) => {
    const navigate = useNavigate();
    const arrowRef = useRef();

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4.2,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 3,
                    dots: true,
                    infinite: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3.2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    className: "center",
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    nextArrow: false,
                    prevArrow: false,
                    dots: false
                },
            }
        ]
    };

    const calculateReadingTime = (text) => {
        const wordsPerMinute = 200; // Adjust this value based on the average reading speed
        const wordCount = text.trim().split(/\s+/).length;
        const readingTimeMinutes = Math.ceil(wordCount / wordsPerMinute);
    
        return readingTimeMinutes;
    };

    return (
        <div className="p-4 slider__trip">
            <Slider ref={arrowRef} {...settings}>
                {
                    blogs?.map((blog) => (
                        <div key={blog._id} className="trips" onClick={() => navigate(`/${navigatation}/${(blog.title).replace(/\s+/g, '-')}`)}>
                            <img src={`/blogImages/${blog?.image}`} alt="" className="trip__image" style={{borderRadius: '10px'}}/>
                            <div className='px-2 d-flex flex-column justify-content-center me-4 mt-2'>
                                <h5 className="m-0 blog-title text-center">{blog.title}</h5>
                            </div>
                            <div className='m-0 px-2 d-flex flex-column justify-content-center me-4 blog-div'>
                                <p className="m-0 text-center blog-para">
                                    <small>
                                        <strong>

                                            {new Date(blog?.date).toLocaleDateString('en-US', {
                                                day: 'numeric',
                                                month: 'short',
                                                year: 'numeric',
                                            })} 
                                        </strong>
                                        {' '}
                                        <span className='m-0'  style={{display: 'inline-block', fontSize: '20px', color: 'gray'}}> • </span>
                                        {' '}
                                        Written by {blog.author}
                                    </small>
                                </p>
                            </div>
                            <div className='px-2 d-flex flex-column justify-content-center me-4 blog-div'>
                                <p className="m-0 text-center ">
                                    <small>
                                        {' '}
                                        <span  style={{display: 'inline-block', fontSize: '20px', color: 'gray'}}> • </span>
                                        {' '}
                                        {calculateReadingTime(blog.description)} min Read
                                    </small>
                                </p>
                            </div>
                        </div>
                    ))
                }
            </Slider>
            {
                blogs && blogs?.length > 4 &&
                <div>
                    <button onClick={() => arrowRef.current.slickPrev()} className='arrow__left'><i className="fa-solid fa-chevron-left"></i></button>
                    <button onClick={() => arrowRef.current.slickNext()} className='arrow__right'><i className="fa-solid fa-chevron-right"></i></button>
                </div>
            }
        </div>
    )
}

export default BlogSlider