import React from 'react'

const OtherInfo = ({ data }) => {
    return (
        <>
            <div className='shd inclusion mb-5'>
                <div className='Mon_font'>
                    {
                        data?.inclusion?.length > 0 &&
                        <>
                            <h2>Inclusion</h2>
                            <div className="hr-line mb-3 m-0"></div>
                        </>
                    }
                    {
                        data?.inclusion?.map((item) => {
                            return (
                                <li className='Mon_font'>{
                                    item.name.split('\n').map((line, i) => (
                                        <div key={i} className='my-2'>
                                            <strong className='accordian-item-dot'>• </strong>
                                            <span className='accordian-item-desc'>{line}</span>
                                        </div>
                                    ))
                                    }
                                </li>
                            )
                        })
                    }
                </div>
                <p></p>
            </div>
            <div className='shd Mon_font mb-5'>
                {
                    data?.exclusion?.length > 0 &&
                    <>
                        <h2>Exclusions</h2>
                        <div className="hr-line mb-3 m-0"></div>
                    </>
                }
                {
                    data?.exclusion?.map((item) => {
                        return (
                            <li>{
                                item.name.split('\n').map((line, i) => (
                                    <div key={i} className='my-2'>
                                        <strong className='accordian-item-dot'>• </strong>
                                        <span className='accordian-item-desc'>{line}</span>
                                    </div>
                                ))
                            }</li>
                        )
                    })
                }
            </div>
        </>
    )
}

export default OtherInfo
