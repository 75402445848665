import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import OtherLayout from '../components/Layout/OtherLayout';
import Accordion from '../components/SingleTrip/Accordion';
import ContactForm from '../components/SingleTrip/ContactForm';
import DatesAndCosting from '../components/SingleTrip/DatesAndCosting';
import OtherInfo from '../components/SingleTrip/OtherInfo';
import '../Style/SingleTrip/SingleTrip.css'
import Videos from '../components/SingleTrip/Videos';
import Gallery from '../components/SingleTrip/Gallery';
import axios from 'axios';
import TripReviews from '../components/SingleTrip/TripReviews';
import PopupForm from '../components/PopupForm';
import SingleTripBottomIcon from '../components/SingleTrip/SingleTripBottomIcon';
import TripSlider from '../components/TripDetails/TripSlider';
import Accomodation from '../components/SingleTrip/Accomodation';
import Costing from '../components/SingleTrip/Costing';
import BookingPage from './BookingPage';

const SingleTrip = () => {
    const [trip, setTrip] = useState(null);
    const [expertForm, setExpertForm] = useState(false);
    const [width, setWidth] = useState(false);
    const [tripCategory, setTripCategory] = useState([]);
    const params = useParams();
    const paramsdata = (params?.id).replace(/-/g, " ");
    console.log(paramsdata)
    const Trip = trip && trip.filter(value => value.heading === paramsdata);
    const activeTrip = trip? Trip[0] : null
    document.title = `${paramsdata}`

    const location = useLocation();

    const singleTripPage = location.pathname === `/trip/${params.id}`
    const bookingPage = location.pathname === `/book/${params.id}`

    // ALL SIMILAR TRIPS CONTAINING THE CATEGORY
    const similarTrips = trip?.filter(item => tripCategory?.some(cate => item?.category?.includes(cate)));
    // REMOVING SAME TRIP Using params id of a trip
    const filterSimilarTrips = similarTrips?.filter(obj => obj.heading !== paramsdata)
    // REMOVING CUSTOMISE TRIPS from the array
    const finalSimilarTrips = filterSimilarTrips?.filter(obj => obj.dates.length > 0)

    const [toggle, setToggle] = useState(1)
    const [toggle2, setToggle2] = useState(1)

    const AllTrips = async () => {
        await axios.get(`api/trips/get-all-trips`).then((res) => {
            setTrip(res.data.data);
            setTripCategory(res.data.data.filter(value => value.heading === paramsdata)[0]?.category)
        }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(() => {
        AllTrips();
        // eslint-disable-next-line
    }, [])
    
    useEffect(() => {
        if (window.innerWidth <= 600) {
            setWidth(true);
        }
        // eslint-disable-next-line
    }, []);
    console.log(activeTrip?.gallery)

    return (
        <>
        { singleTripPage && 
        <OtherLayout background={`https://tripgix.com/tripimage/${activeTrip?.image?.replace(/ /g, '%20')}`} setExpertForm={setExpertForm} activeTrip={activeTrip} title={activeTrip?.heading} subTitle={activeTrip?.thoughts}>
            <div>
                {/* ABOUT US  */}
                    <div className="container-fluid about__main p-4 p-lg-5 mb-4 mb-lg-0">
                        <div className="row d-flex">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                                <div className="about">
                                    <div className="icons__trip">
                                        <div className='d-flex justify-content-center flex-column align-items-center Mon_font pe-3'>
                                            <div className='i__con'>
                                                <i className="fa-regular fa-clock trip_icon"></i>
                                            </div>
                                            <p className='m-0 text-center text-secondary mb-1'>Days of Trip</p>
                                            <span className='content_text_grey text-dark'>{activeTrip?.duration}</span>
                                        </div>
                                        <div className=' d-flex justify-content-center flex-column align-items-center Mon_font'>
                                            <div className="i__con">
                                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                            </div>
                                            <p className='m-0 text-center text-secondary mb-1'>Starting Price</p>
                                            <span className='content_text_grey text-dark'>₹{activeTrip?.costing?.reduce((min, obj) => {
                                                return obj?.price < min ? obj?.price : min;
                                            }, Infinity)} /-</span>
                                        </div>
                                        <div className='d-flex justify-content-center flex-column align-items-center Mon_font'>
                                            <div className="i__con">
                                                <i className="fa-solid fa-location-dot"></i>
                                            </div>
                                            <p className='m-0 text-center text-secondary mb-1'>Pickup & Drop</p>
                                            <span className='content_text_grey text-dark'>{activeTrip?.departure}</span>
                                        </div>
                                    </div>
                                    {/* <div className="book__now__button mt-4">
                                <button onClick={() => bookingHandler(activeTrip?._id)}>Book Now</button>
                            </div> */}

                                    <div className='my-4 about__tab__buttons Mon_font'>
                                        <button className={`${toggle === 1 ? "active" : ""}`} onClick={() => {
                                            if (toggle === 0 || toggle === 2 || toggle === 3) {
                                                setToggle(1)
                                            } else {
                                                setToggle(0)
                                            }
                                        }}>Day Wise Plan</button>
                                        <button className={`mx-lg-4 mx-2 ${toggle === 2 ? "active" : ""}`} onClick={() => {
                                            if (toggle === 0 || toggle === 1 || toggle === 3) {
                                                setToggle(2)
                                            } else {
                                                setToggle(0)
                                            }
                                        }}>
                                            {
                                                activeTrip?.customised === "true" ? "Costing" : "Dates & Costing"
                                            }
                                        </button>
                                        <button className={`${toggle === 3 ? "active" : ""}`} onClick={() => {
                                            if (toggle === 0 || toggle === 1 || toggle === 2) {
                                                setToggle(3)
                                            } else {
                                                setToggle(0)
                                            }
                                        }}>Inclusions & Excl.</button>
                                    </div>
                                    <div className={`shd-accordian mb-5 ${toggle === 1 ? "show__content" : "content"}`}>
                                        <Accordion data={activeTrip?.itinerary} trip_data={activeTrip} />
                                    </div>
                                    <div className={`mb-5 ${toggle === 2 ? "show__content" : "content"}`}>
                                        <DatesAndCosting data={activeTrip} itinerary={activeTrip?.itinerary} />
                                    </div>
                                    <div className={`${toggle === 3 ? "show__content" : "content"}`}>
                                        {/* Inclusion and Exclusion is here  */}
                                        <OtherInfo data={activeTrip} />
                                    </div>

                                    <div className='Mon_font shd mb-4'>
                                        {
                                            activeTrip?.notes?.length > 0 &&
                                            <>
                                                <h4>Notes:</h4>
                                                <div className="hr-line m-0 mb-3"></div>
                                            </>
                                        }
                                        {
                                            activeTrip && activeTrip?.notes?.map((item) => {
                                                return (
                                                    <p className='text-secondary'><strong>• </strong><small>{item?.name}</small></p>
                                                )
                                            })
                                        }
                                    </div>

                                    {/* <div className='shd mb-5 must__carry'>
                                <h4 className='Mon_font'>Must Carry</h4>
                                <div className="hr-line mb-3 m-0"></div>
                                <div className='Mon_font fw-light text-secondary m-0 p-0'>
                                    <ul className='m-0 p-0' style={{ listStyle: "circle !important" }}>
                                        {
                                            activeTrip?.mustcarry?.map((item, index) => {
                                                return <div key={index}>
                                                    <li><small>- {item.name}</small></li>
                                                </div>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div> */}
                                </div>
                            </div>
                            {/* CONTACT FORM  */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-5 mt-5 mt-lg-0">
                                <Costing data={activeTrip} />
                                <div className='single-trip-form mb-4'>
                                    <ContactForm />
                                </div>
                            </div>
                            
                            <div className="col-12">
                                <div className='shd mb-5 things__to__carry'>
                                    <div className='my-4 about__tab__buttons Mon_font d-flex justify-content-between align-items-center'>
                                        <button className={`${toggle2 === 1 ? "active" : ""}`} onClick={() => {
                                            if (toggle2 === 0 || toggle2 === 2 || toggle2 === 3) {
                                                setToggle2(1)
                                            } else {
                                                setToggle2(0)
                                            }
                                        }}>Cancellation {width && <br />} Policy</button>
                                        <button className={`mx-lg-4 mx-2 ${toggle2 === 2 ? "active" : ""}`} onClick={() => {
                                            if (toggle2 === 0 || toggle2 === 1 || toggle2 === 3) {
                                                setToggle2(2)
                                            } else {
                                                setToggle2(0)
                                            }
                                        }}>Terms and {width && <br />} Conditions</button>
                                        <button className={`${toggle2 === 3 ? "active" : ""}`} onClick={() => {
                                            if (toggle2 === 0 || toggle2 === 1 || toggle2 === 2) {
                                                setToggle2(3)
                                            } else {
                                                setToggle2(0)
                                            }
                                        }}>Things to {width && <br />} Carry</button>
                                    </div>
                                    <div className={`border p-3 mb-5 ${toggle2 === 1 ? "show__content" : "content"}`}>
                                        <h6 className='Mon_font mb-2'><strong>No Refund shall be made with respect to the initial booking amount for any cancellations. However,</strong></h6>
                                        <div className='Mon_font fw-light text-secondary m-0 p-0'>
                                            <ul className='m-0 p-0' style={{ listStyle: "circle !important" }}>
                                                <li style={{ fontWeight: '400' }} className='my-2'><small><span >•</span> If cancellations are made 30 days before the start date of the trip, 50% of the trip cost will be charged as cancellation fees.</small></li>
                                                <li style={{ fontWeight: '400' }} className='my-2'><small><span >•</span> If cancellations are made 15-30 days before the start date of the trip, 75% of the trip cost will be charged as cancellation fees.</small></li>
                                                <li style={{ fontWeight: '400' }} className='my-2'><small><span >•</span> If cancellations are made within 0-15 days before the start date of the trip, 100% of the trip cost will be charged as cancellation fees.</small></li>
                                                <li style={{ fontWeight: '400' }} className='my-2'><small><span >•</span> In the case of unforeseen weather conditions or government restrictions, certain activities may be cancelled and in such cases, the operator will try his best to provide an alternate feasible activity. However, no refund will be provided for the same.</small></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={`border p-3 mb-5 ${toggle2 === 2 ? "show__content" : "content"}`}>
                                        {
                                            activeTrip && activeTrip?.tnc?.map((item, index) => {
                                                return <div key={index}>
                                                    <div className='Mon_font fw-light text-secondary m-0 p-0'>
                                                        <ul className='m-0 p-0' style={{ listStyle: "circle !important" }}>
                                                            <li className='mb-3' style={{fontWeight: '400'}}><strong>•</strong><small> {item?.name}</small></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className={`border p-3 mb-5 ${toggle2 === 3 ? "show__content" : "content"}`}>
                                        {
                                            activeTrip && activeTrip?.thingstocarry?.map((item, index) => {
                                                return <div key={index}>
                                                    <h6 className='Mon_font m-0 mb-3'><strong>•</strong> {item?.name}</h6>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <SingleTripBottomIcon />
                                <Accomodation trip={activeTrip} />
                                <Gallery trip={activeTrip} />
                                <Videos trip={activeTrip} />
                                <TripReviews trip={activeTrip} />
                            </div>
                        </div>
                    </div>
                <div>
                    {
                        filterSimilarTrips?.length > 0 &&
                        <div className='p-lg-5 pt-lg-0 mb-4 '>
                            <h2 className='similar__trips__heading Mon_font text-center'><small>Similar Trips</small></h2>
                            <div className='d-flex justify-content-center text-align-center'>
                                <div className="hr-line m-lg-0 mb-3"></div>
                            </div>
                            <TripSlider trips={finalSimilarTrips} similarTrips='true'/>
                        </div>
                    }
                </div>
                {
                    expertForm &&
                    <PopupForm expertForm={expertForm} setExpertForm={setExpertForm} />
                }
            </div>
        </OtherLayout>}

        {
            bookingPage &&
            <BookingPage trip={trip}/>
        }
        </>
    )
}

export default SingleTrip
