import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import '../../Style/SideMenu/style.css';
import contactme from '../../images/5138237.png'
import axios from 'axios';

const SideMenu = ({ sideMenu, setSidemenu }) => {
    const navigate = useNavigate();
    const [allCategory, setAllCategory] = useState(false);

    // get all category
    const getAllCategory = async () => {
        await axios.get(`api/category/get-all-category`).then((res) => {
            if (res.data.data) {
                setAllCategory(res.data.data)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getAllCategory();
    }, [])

    return (
        <div className={`${sideMenu ? "sideMenu sideMenu__open" : "sideMenu"} sticky-top top-sticky`}>
            <ul>
                {
                    allCategory && allCategory?.map((obj) => {
                        return <li key={obj._id}>
                            <Link to={`/category/${obj.name}`}>{obj.name}</Link>
                        </li>
                    }).slice(allCategory.length - 2, allCategory.length)
                }
                <li>
                    <Link onClick={() => setSidemenu(false)} to='/about'>About Us</Link>
                </li>
                <li>
                    <Link onClick={() => setSidemenu(false)} to='/contact'>Contact Us</Link>
                </li>
                <li>
                    <Link onClick={() => setSidemenu(false)} to='/blog'>Blogs</Link>
                </li>
                <li>
                    <Link onClick={() => setSidemenu(false)} to='/career'>Career</Link>
                </li>
                <div className='text-center Mon_font'>
                    <img src={contactme} alt="" />
                    <button onClick={() => navigate("/contact")} className='btn btn-warning'>Talk to our expert</button>
                </div>
            </ul>
        </div>
    )
}

export default SideMenu
