import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import TalkToExpert from '../components/LandingPage/TalkToExpert';
import Testmonials from '../components/LandingPage/Testmonials';
import OtherLayout from '../components/Layout/OtherLayout';
import axios from 'axios';
import noresult from '../images/noresult.png';
import '../Style/CategoryListPage/Category.css';
import blogBG from '../images/blogs/blog.jpg';

const CategoryBlogPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [blogs, setBlogs] = useState(null);
    const [allCategory, setAllCategory] = useState(null);
    const [newCategoryArray, setNewCategoryArrya] = useState([]);
    const [categoryBlogs, setCategoryBlogs] = useState(null);
    const [uniqueCity, setUniqueCity] = useState(null);
    const [singleBlog, setSingleBlog] = useState(null);
    const [singleBlogDesc, setSingleBlogDec] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const paramscategory = params?.category && (params?.category).replace(/-/g, " ");
    const paramstitle = params?.title && (params?.title).replace(/-/g, " ");
    
    // get all Blogs
    const getAllBlog = async (elm) => {
        await axios.get(`api/blog/get-all-blog`).then((res) => {
            let allBlogs = res.data.data
            setBlogs(allBlogs.reverse())

            if(paramscategory){
                const categoryBlogsData = allBlogs?.filter((blog)=>{
                    return blog.category === paramscategory
                })
                if(categoryBlogsData){
                    setCategoryBlogs(categoryBlogsData)
                    document.title = `Tripgix - ${paramscategory}`
                }
            }

            if(paramstitle){
                document.title = `${paramstitle}`
                const singleBlogData = allBlogs?.filter((blog)=>{
                    return blog.title === paramstitle
                })
                if(singleBlogData){
                    setSingleBlog(singleBlogData[0])
                    setSingleBlogDec((singleBlogData[0]?.description).split('\n'))
                }
                const categoryBlogsData = allBlogs?.filter((blog)=>{
                    return blog.category === singleBlogData[0].category
                })
                if(categoryBlogsData){
                    setCategoryBlogs(categoryBlogsData.filter((data)=>{
                        return data.title !== singleBlogData[0].title
                    }))
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    //get All trip for unique location
    const getAllTrips = async () => {
        await axios.get(`api/trips/get-all-trips`).then((res) => {
            let locations = []
            if(res.data.data){
                res.data?.data?.forEach(trip => {
                    locations.push((trip?.location).replace(/\s+/g, '-'));
                });
            }
            setUniqueCity([...new Set(locations)])
            
            }).catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        if (blogs === null) {
            getAllBlog();
        }
        if(params.category){
            getAllBlog()
        }
        if(params.title){
            getAllBlog()
        }
        // eslint-disable-next-line
    }, [paramscategory, paramstitle]);

    const calculateReadingTime = (text) => {
        const wordsPerMinute = 200; // Adjust this value based on the average reading speed
        const wordCount = text?.trim().split(/\s+/).length;
        const readingTimeMinutes = Math.ceil(wordCount / wordsPerMinute);
    
        return readingTimeMinutes;
    };

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    // get all trips
    const getAllCategory = async (elm) => {
        await axios.get(`api/category/get-all-category`).then((res) => {
            let arr = res.data.data;
            setAllCategory(arr.reverse());
            setNewCategoryArrya(arr?.map(obj=> (obj.name).replace(/\s+/g, '-')));
            }).catch((err) => {
                console.log(err);
            });
    }
    
    useEffect(() => {
        if (allCategory === null) {
            getAllCategory();
        } 
        if (uniqueCity === null) {
            getAllTrips();
        } 
        // eslint-disable-next-line
    }, [paramstitle])

    const blogDescription = (desc) => {
        const words = desc.split(' ');
        return (
            <div style={{textAlign: 'justify', fontSize: '13px'}}>
                {
                words.map((word, index)=>{
                    const matchedCategory = newCategoryArray.find(category => category === word);
                    const matchLocation = uniqueCity?.find(location => location === word) || matchedCategory;
                    if (matchLocation) {
                        return (
                          <Link className='blog-desc-link' key={index} to={`/category/${word}`}>
                            {word + ' '}
                          </Link>
                        );
                    }
                    return word + ' ';
                })
                 }
            </div>
        )                                 
    }

    const blogExtraSubTitle = `Discover the world with our travel hub, Find destination guides, itineraries, and inspiring stories to fuel your wanderlust and connect with fello travellersw. Lets explore together!`
    return (
        <>
            {
                <OtherLayout background={paramscategory? blogBG : `/blogImages/${singleBlog?.image}`} title={paramscategory || singleBlog?.category} extraSubTitle={singleBlog? singleBlog?.title : blogExtraSubTitle}>
                    <div className="category__cont container-fluid px-lg-5 px-4 Mon_font my-5">
                        {
                        paramscategory &&
                            <h2 className='text-start category__heading mb-4 fw-bold mb-5'>Our {paramscategory}</h2>
                        }
                        {paramscategory && <div className="row">
                            {
                            categoryBlogs?.map((blog) => {
                                const date = new Date(blog.createdAt);
                                const day = date.toLocaleString('default', { day: 'numeric'});
                                const month = date.toLocaleString('default', { month: 'short'});
                                const year = date.toLocaleString('default', { year: 'numeric'});
                                return (
                                    <>
                                    <div key={blog._id} onClick={() => navigate(`/${params.category}/${(blog?.title)?.replace(/\s+/g, '-')}`)} className="shd mb-5 p-0 border-0 trip__container col-12 col-sm-12 col-lg-4 col-md-6 mx-lg-4">
                                        <div className="trip__img__container" style={{ background: `url(${`/blogImages/${blog?.image}`}` }}></div>
                                        <div className='py-3 px-3'>
                                            <div className="text-start">
                                                <h5 className="my-1" style={titleStyle}>{blog.title}</h5>
                                            </div>
                                            <div className='m-0 py-1'>
                                                <p className='m-0' style={dateStyle}><i className="fa-solid fa-pen me-2"></i>Written by {blog.author}</p>
                                            </div>
                                            <div className='m-0 py-1 d-flex justify-content-between align-items-center me-3'>
                                                <p className='m-0' style={dateStyle}>{day + ' ' + month + ' ' + year}</p>
                                                <p className='m-0' style={dateStyle}><strong>• </strong>{calculateReadingTime(blog.description)} Min Read</p>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                )
                            })
                            }
                        </div>}

                        {paramstitle && <div className={`${windowWidth < 1160 ? 'column' : 'row'}`}>
                            <div className={`mb-5 border border-2 p-0 rounded-0 trip__container col-12 col-sm-12 col-lg-4 col-md-6`} style={{width: `${windowWidth < 1160 ? '100%' : '70%'}`, cursor: 'default'}}>
                                <div className='py-3 px-3'>
                                    <div className="text-start">
                                        <h4 className="my-1"><strong>{singleBlog?.title}</strong></h4>
                                    </div>
                                    <div className='m-0 py-1 d-flex justify-content-between align-items-center me-3'>
                                        <p className='m-0' style={dateStyle}><strong>• </strong>{calculateReadingTime(singleBlog?.description)} Min Read</p>
                                    </div>
                                    <div className='m-0 py-1 mb-2'>
                                        <p className='m-0' style={dateStyle}><i className="fa-solid fa-pen me-2"></i>Written by {singleBlog?.author}</p>
                                        <hr  className={`${windowWidth < 1160 ? 'my-2' : 'd-none'}`}/>
                                    </div>
                                    <div className='m-0 py-1 mb-5'>
                                        {singleBlogDesc?.map((desc)=>{
                                            return <div key={desc}> 
                                                {blogDescription(desc)}
                                                {/* <p className='m-0 text-dark' style={dateStyle}>{desc}</p> */}
                                            </div>
                                        })}
                                    </div>
                                    <hr className={`${windowWidth < 1160 ? 'd-none' : null}`}/>
                                    {categoryBlogs &&
                                    <div className={`${windowWidth < 1160 ? 'd-none' : 'd-flex'}`} style={{cursor: 'pointer'}}>
                                        {
                                        categoryBlogs?.map((blog, index) => {
                                            const date = new Date(blog.createdAt);
                                            const day = date.toLocaleString('default', { day: 'numeric'});
                                            const month = date.toLocaleString('default', { month: 'short'});
                                            const year = date.toLocaleString('default', { year: 'numeric'});
                                            if (index <= 4) {
                                                return (
                                                <>
                                                <div key={blog._id} onClick={() => navigate(`/${(singleBlog.category).replace(/\s+/g, '-')}/${(blog?.title)?.replace(/\s+/g, '-')}`)} className="mx-2 border" style={{width: '227px'}}>
                                                    <img width={224} height={160} src={`/blogImages/${blog?.image}`} alt="" />
                                                    <div>
                                                        <h6 className='p-2' style={{fontSize: '13px', fontWeight: '600'}}>{(blog.title).length > 50 ? (blog.title).substring(0, 50) + '...' : blog.title}</h6>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h6 className='p-2' style={{fontSize: '11px'}}><i className="fa-solid fa-pen me-2"></i>{blog.author}</h6>
                                                            <h6 className='p-2' style={{fontSize: '11px'}}>{day + ' ' + month + ' ' + year}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                            )
                                        }
                                        })
                                        }
                                    </div>}
                                </div>
                            </div>
                            <div className={`${windowWidth < 1160 ? 'p-0' : ''}`} style={{width: `${windowWidth < 1160 ? '100%' : '27%'}`, cursor: 'pointer'}}>
                                {
                                    blogs && blogs?.map((blog, index)=>{
                                        const date = new Date(blog.createdAt);
                                        const day = date.toLocaleString('default', { day: 'numeric'});
                                        const month = date.toLocaleString('default', { month: 'short'});
                                        const year = date.toLocaleString('default', { year: 'numeric'});
                                        if (index <= 5) {
                                            return (
                                            <>
                                            <div key={blog._id} onClick={() => navigate(`/${(singleBlog.category).replace(/\s+/g, '-')}/${(blog?.title)?.replace(/\s+/g, '-')}`)} className="p-2 mb-3 border d-flex">
                                                <div style={{width: '170px'}}>
                                                    <img  className='img-fluid' src={`/blogImages/${blog?.image}`} alt="" style={{height: '100px', width: '100%'}} />
                                                </div>
                                                <div>
                                                    <h6 className='p-2' style={{fontSize: '13px', fontWeight: '600'}}>{(blog.title).length > 50 ? (blog.title).substring(0, 50) + '...' : blog.title}</h6>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <h6 className='p-2' style={{fontSize: '11px'}}><i className="fa-solid fa-pen me-2"></i>{blog.author}</h6>
                                                        <h6 className='p-2' style={{fontSize: '11px'}}>{day + ' ' + month + ' ' + year}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                        )}
                                    })
                                }    
                            </div>
                        </div>}
                    </div>
                    <TalkToExpert />
                    <div className='my-5'>
                        <Testmonials />
                    </div>
                </OtherLayout>
            }
        </>
    )
}

const titleStyle = {
    fontSize: '17px',
    fontWeight: 600
}
const dateStyle = {
    fontSize: '14px',
    fontWeight: 400,
    color: 'gray'
}

export default CategoryBlogPage
