import React from 'react';
import Header from '../Header/Header';
import HeroSection from '../LandingPage/HeroSection'
import { useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer';
import HomeBanner from '../../images/LandingPage/homePage.webp'
const Layout = ({ children, title, subTitle, trip }) => {
    const location = useLocation();
    const isHome = location.pathname === "/";
    // const isAbout = location.pathname === "/about";

    return (
        <div className='main_landing'>
            {/* we can add background from url in below container using style  */}
            <div className={`main_bg_cover ${!isHome ? 'about__height' : ''}`} style={{ background: `linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(${isHome ? HomeBanner : ""})` }}>
                <Header />
                <HeroSection trip={trip} bg={HomeBanner} title={title} subTitle={subTitle} />
            </div>
            <div>
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default Layout
