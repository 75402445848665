import React, { useEffect, useState } from 'react';
import Layout from '../../components/Admin/AdminLayout';
import AdminHeader from './component/AdminHeader';
import Swal from 'sweetalert2'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import Pagination from './component/Pagination';
import SearchBookingList from './component/SearchBookingList';
import noresult from '../../images/noresult.png';

const Application = () => {
  const navigate = useNavigate()
  const [jobApplications, setJobApplication] = useState(null);
  const [totalData, setTotalData] = useState(null)
  const [status, setStatus] = useState(null);
  const Month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

  const getAllJobApplications = (status) => {
    axios({
        method: "get",
        withCredentials: true,
        url: `api/career/get-all-job-applications`,
    }).then((res) => {
        let arr = res.data.data;
        setJobApplication(arr.reverse());
    }).catch((err) => {
        console.log(err);
    });
  }

  useEffect(()=>{
    getAllJobApplications()
    // eslint-disable-next-line
  }, [])

    //pagination part
    const [currentPage, setCurrentPage] = useState(1);
    const [jobPerPage, setJobPerPage] = useState(10);

    const indexOfLastJob = currentPage * jobPerPage ;
    const indexOfFirstJob = indexOfLastJob - jobPerPage;

    const currentJobList = jobApplications?.slice(indexOfFirstJob, indexOfLastJob);
    const totalJobApplication = jobApplications?.length;

    //to upper case function
    const toUpperCase = (string) =>{
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    }

    //on handle click inquiry 
    const onHandleBooking = (value)=>{
        setStatus(value)
    }

    //delete booking trip
    const deleteBooking = (id)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            // If the user confirms the deletion
            if (result.isConfirmed) {
              // Call your delete function to remove the data
                axios({
                    method: "POST",
                    data: {
                        id: id,
                    },
                    withCredentials: true,
                    url: `api/career/delete-job-application`,
                }).then((res) => {
                    getAllJobApplications(status)
                    message.success(res.data.message)
                }).catch((err) => {
                    console.log(err);
                });
            }
        });
    }

  return (
    <Layout>
        <AdminHeader title='Career / Job Applications' redirect='/admin-career'/>
        <div className="container Mon_font px-4 pb-4 Mon_font">
            Total Applications : {jobApplications?.length}
            {jobApplications?.length === 0?
            <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
                <img src={noresult} alt="" width={150}/>
                <h3>No Record Found</h3>
            </div> :
            <div className="">
                <div>
                    {currentJobList && currentJobList?.map((data, index)=>{
                         const date = new Date(data?.createdAt)
                         const month = date.toLocaleString('default', {month:'short'})
                         const day = date.toLocaleString('default', {day:'numeric'})
                         const year = date.toLocaleString('default', {year:'numeric'})
                        return <div  key={data._id} className='position-relative'>
                            {<div>
                                <div id='all-booking-list' className='rounded border bg-white shd my-4 p-3 d-flex justify-content-between align-items-center' style={{cursor: 'default'}}>
                                    <div className='' id='all-booking-list-item'>
                                      <div className='d-flex gap-5'>
                                        <span className='m-0 mb-2 fs-5'><strong>{toUpperCase(data?.name)}</strong></span>
                                        <span className='m-0 mb-2 fs-5 text-danger'><strong>{toUpperCase(data?.number)}</strong></span>
                                        <span className='m-0 mb-2 fs-5'><strong>{toUpperCase(data?.email)}</strong></span>
                                        <span className='m-0 mb-2 fs-5'><strong>{day + ' ' + month + ' ' + year}</strong></span>
                                      </div>
                                      <hr className='m-0 mb-3'/>
                                      <div className=''>  
                                        <p className='m-0 my-1'><strong>Position: </strong>{data?.position}</p>
                                        <p className='m-0 my-1'><strong>Experience: </strong>{data?.experience}</p>
                                        <p className='m-0 my-1'><strong>Job Type: </strong>{data?.jobType}</p>
                                        <p className='m-0 my-1'><strong>Description: </strong>{data?.jobDes}</p>
                                      </div>
                                    </div>
                                    <div className='d-flex gap-3 flex-column'>
                                        <button className='btn btn-sm btn-danger' onClick={(event)=>{
                                            deleteBooking(data?._id)
                                            event.stopPropagation();
                                            }}>Delete</button>
                                        <Link to={`https://tripgix.com/jobPdf/${data?.resume}`} target='_blank' className='btn btn-sm btn-danger' onClick={(event)=>{
                                            event.stopPropagation();
                                            }}>Resume</Link>
                                    </div>
                                </div>
                                <span className="position-absolute top-0 start-25 translate-middle badge rounded-pill bg-info text-dark">
                                    {index+1}
                                </span>
                            </div>}
                        </div>
                    })}
                </div>
            </div>}
            {jobApplications?.length === 0? '' : <Pagination totalInquiry={totalJobApplication} setCurrentPage={setCurrentPage} inquiryPerPage={jobPerPage} currentPage={currentPage}/>}
        </div>
    </Layout>
  )
}

export default Application
