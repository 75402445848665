import React, { useState } from 'react';
import image from '../../images/blogs/blog.jpg';
import '../../components/TripDetails/DemoSlider.css'

const DemoSlider = () => {
  return (
    <div>

    </div>
  )
}

export default DemoSlider
