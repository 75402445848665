import React from 'react';
import support from '../../images/SingleTrip/24-hours-support.png';
import Policy from '../../images/SingleTrip/delete-cart.png';
import Captains from '../../images/SingleTrip/pilot.png';
import Consultans from '../../images/SingleTrip/customer-support.png';
import '../../Style/SingleTrip/SingleTripBottomIcon.css'

const SingleTripBottomIcon = () => {
    return (
        <div className="single-trip-bottom-icon">
            <div className='single-trip-bottom-icon-div'>
                <img src={support} alt="" />
                <h6 className='m-0 mt-3 text-center'>24/7 Backend Support</h6>
            </div>
            <div className='single-trip-bottom-icon-div'>
                <img src={Policy} alt="" />
                <h6 className='m-0 mt-3 text-center d-none d-md-block d-lg-block'>Flexible Cancellation Policy</h6>
                <h6 className='m-0 mt-3 text-center d-block d-lg-none d-md-none'>Flexible Cancellation</h6>
            </div>
            <div className='single-trip-bottom-icon-div'>
                <img src={Captains} alt="" />
                <h6 className='m-0 mt-3 text-center'>Experienced Trip Captains</h6>
            </div>
            <div className='single-trip-bottom-icon-div'>
                <img src={Consultans} alt="" />
                <h6 className='m-0 mt-3 text-center'>Certified Travel Consultants</h6>
            </div>
        </div>
    )
}

export default SingleTripBottomIcon
