import React, { useEffect, useState } from 'react';
import Layout from '../../components/Admin/AdminLayout';
import AdminHeader from './component/AdminHeader';
import Swal from 'sweetalert2'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import Pagination from './component/Pagination';
import SearchBookingList from './component/SearchBookingList';
import noresult from '../../images/noresult.png';

const BookingList = () => {
    const navigate = useNavigate()
    const [allBookings, setAllBookings] = useState(null);
    const [totalData, setTotalData] = useState(null)
    const [status, setStatus] = useState(null);
    const Month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

    //pagination part
    const [currentPage, setCurrentPage] = useState(1);
    const [bookingPerPage, setBookingPerPage] = useState(10);

    const indexOfLastBooking = currentPage * bookingPerPage ;
    const indexOfFirstBooking = indexOfLastBooking - bookingPerPage;

    const currentBookingList = allBookings?.slice(indexOfFirstBooking, indexOfLastBooking);
    const totalInquiry = allBookings?.length;

    //to upper case function
    const toUpperCase = (string) =>{
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    }

    //get all inquiry
    const getAllBookingDetails = (status)=>{
        axios({
            method: "get",
            withCredentials: true,
            url: `api/booking/get-all-booking`,
        }).then((res) => {
            let arr = res.data.data
            console.log(res.data.data)
            let newArr = arr.reverse()

            let newdate = new Date().getDate();
            let newmonth = new Date().getMonth();
            let newyear = new Date().getFullYear();
            let currentDate = newyear+'-'+Month[newmonth]+'-'+newdate
            let newCurrentdate = new Date(currentDate).toISOString().slice(0, 10);

            const getCurrentBooking = (status)=>{
                //get current booking
                let AllBookings = newArr?.filter((booking)=>{
                    let date = new Date(booking.details.split(' ')[0])
                    let newDate = date.getDate()
                    let newMonth = date.getMonth()
                    let newYear = date.getFullYear()
                    let bookingDate = newYear+'-'+Month[newMonth]+'-'+newDate
                    let newBookingDate = new Date(bookingDate).toISOString().slice(0, 10);

                    let data
                    if(status === null || status === 'current'){
                        data = newBookingDate >= newCurrentdate
                    }
                    if(status === 'expired'){
                        data = newBookingDate < newCurrentdate
                    }
                    return data
                })
                setAllBookings(AllBookings);
                setTotalData(AllBookings?.length)
            }

            if(status === 'expired'){
                getCurrentBooking(status)
            } else if(status === 'current'){
                getCurrentBooking(status)
            } else if(status === null) {
                getCurrentBooking(status)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    //on handle click inquiry 
    const onHandleBooking = (value)=>{
        setStatus(value)
    }

    //delete booking trip
    const deleteBooking = (id)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            // If the user confirms the deletion
            if (result.isConfirmed) {
              // Call your delete function to remove the data
                axios({
                    method: "POST",
                    data: {
                        id: id,
                    },
                    withCredentials: true,
                    url: `api/booking/delete-booking`,
                }).then((res) => {
                    getAllBookingDetails(status)
                    message.success(res.data.message)
                }).catch((err) => {
                    console.log(err);
                });
            }
        });
    }
    
    //use Effect hook function
    useEffect(()=>{
        if(status === null){
            getAllBookingDetails(status)
        } else {
            getAllBookingDetails(status)
        }
        // eslint-disable-next-line
    }, [status])

    
  return (
    <Layout>
        <AdminHeader title='Trip Booking List' redirect='/admin-dashboard'/>
        <div className="container Mon_font px-4 pb-4 Mon_font">
            <div className='py-3 d-flex justify-content-between align-items-center'>
                <div>
                    <SearchBookingList setProduct={setAllBookings}/>
                </div>
                <div className='d-flex'>
                    <div className="btn-group d-flex justify-content-center align-items-center">
                        <p className='m-0 px-3 py-2 bg-warning rounded me-2'>Total Booking:</p>
                        <p className='bg-info m-0 px-3 py-2 rounded me-2'>{totalData? totalData : '0'}</p>
                    </div>
                    <div className="btn-group bg-warning border-0">
                        <button className="btn btn-secondary dropdown-toggle bg-transparent text-black px-4 py-2 border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {status? toUpperCase(status) : ''} Booking
                        </button>
                        <ul className="dropdown-menu">
                            <li><Link className="dropdown-item" to="#" onClick={()=>{onHandleBooking('current')}}>Current Booking</Link></li>
                            <li><Link className="dropdown-item" to="#" onClick={()=>{onHandleBooking('expired')}}>Expired Booking</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr />
            {allBookings?.length === 0?
            <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
                <img src={noresult} alt="" width={150}/>
                <h3>No Record Found</h3>
            </div> :
            <div className="">
                <div>
                    {currentBookingList && currentBookingList?.map((booking, index)=>{
                        const date = new Date(booking?.createdAt);
                        const options = { year: 'numeric', month: 'short', day: 'numeric' };
                        const formattedDate = date.toLocaleDateString('en-US', options);
                        return <div  key={booking._id} className='position-relative'>
                            {<div>
                                <div id='all-booking-list' className='rounded border my-4 p-3 d-flex justify-content-between align-items-center'
                                    onClick={()=>{navigate(`/booking-trip/${booking?._id}`)}}
                                >
                                    <div>
                                        <h5 className='m-0' id='all-booking-list-item'>
                                            <strong>BookingId: <mark className='bg-warning p-0 m-0 px-2'>{booking?.sequence}</mark></strong>
                                            <strong className='ms-5'>{booking?.title}</strong>
                                            <strong className='ms-5 text-danger'>{booking?.tripId?.location}</strong>
                                            <strong className='ms-5'>Trip Date: {booking?.details?.split(' ')[0]}</strong>
                                        </h5>
                                        <hr />
                                        <div className='' id='all-booking-list-item'>
                                            <h5 className='m-0 mb-2'><strong>{toUpperCase(booking?.username)}</strong></h5>
                                            <p className='m-0'>from {toUpperCase(booking?.address)}</p>
                                            <p className='m-0'><strong>Phone</strong> {toUpperCase(booking?.phone)}</p>
                                            <p className='m-0'><strong>Booking Date: </strong> {formattedDate}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex gap-3'>
                                        <button className='btn btn-sm btn-danger' onClick={(event)=>{
                                            deleteBooking(booking?._id)
                                            event.stopPropagation();
                                            }}>Delete</button>
                                    </div>
                                </div>
                                <span className="position-absolute top-0 start-25 translate-middle badge rounded-pill bg-info text-dark">
                                    {index+1}
                                </span>
                            </div>}
                        </div>
                    })}
                </div>
            </div>}
            {allBookings?.length === 0? '' : <Pagination totalInquiry={totalInquiry} setCurrentPage={setCurrentPage} inquiryPerPage={bookingPerPage} currentPage={currentPage}/>}
        </div>
    </Layout>
  )
}

export default BookingList
