import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../../Style/Header/BlackHeader.css'
import BackDrop from '../SideMenu/BackDrop'
import SideMenu from '../SideMenu/SideMenu'
import tripgix from '../../images/LandingPage/logo/tripgix.png'
import axios from 'axios'

const Header = () => {
    const [navbar, setNavbar] = useState(false);
    const [sideMenu, setSidemenu] = useState(false);
    const [domain, setDomain] = useState(false);
    const [allCategory, setAllCategory] = useState(false);

    // get all category
    const getAllCategory = async () => {
        await axios.get(`api/category/get-all-category`).then((res) => {
            if (res.data.data) {
                setAllCategory(res.data.data)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const NavbarBgChange = () => {
        if (window.scrollY >= 60) {
            setNavbar(true)
            setDomain(true)
        } else {
            setNavbar(false)
            setDomain(false)
        }
    }
    window.addEventListener('scroll', NavbarBgChange)

    const toggleMenu = () => {
        setSidemenu(!sideMenu)
    }

    useEffect(() => {
        getAllCategory();
    }, [])

    return (
        <>
            <BackDrop sideMenu={sideMenu} closeSideMenu={toggleMenu} />
            <SideMenu sideMenu={sideMenu} setSidemenu={setSidemenu} />
            <nav className={`black navbar ${navbar ? "active" : ""} fixed-top`}>
                <Link to='/'>
                    <div className="logo d-flex align-items-center">
                        {
                            domain ?
                                <h6 className='text-white m-0'><b>Tripgix.com</b></h6>
                                :
                                <img width={60} src={tripgix} alt="" />
                        }
                    </div>
                </Link>
                <div className="contact_number"><span><i className='me-2 fa-solid fa-phone'></i>+91 8383-0808-60</span></div>
                <div className="menu_list d-none d-lg-block">
                    <ul className='d-flex'>
                        {
                            allCategory && allCategory?.map((obj) => {
                                return <li key={obj._id}>
                                    <Link to={`/category/${obj.name}`}>{obj.name}</Link>
                                </li>
                            }).slice(allCategory.length - 2, allCategory.length)
                        }
                        <li>
                            <Link to='/about'>ABOUT US</Link>
                        </li>
                        <li>
                            <Link to='/contact'>CONTACT US</Link>
                        </li>
                    </ul>
                </div>
                <div className="nav_toggle_button d-lg-none" onClick={toggleMenu}>
                    {
                        sideMenu ?
                            <i className="fa-solid fa-xmark"></i>
                            :
                            <i className="fa-solid fa-bars"></i>
                    }
                </div>
            </nav>
        </>

    )
}

export default Header
