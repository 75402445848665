import React from 'react'

const Pagination = ({totalInquiry, inquiryPerPage, setCurrentPage, currentPage}) => {
    const pageNumbers = [];
    for(let i=1; i<=Math.ceil(totalInquiry / inquiryPerPage); i++){
        pageNumbers.push(i);
    }
  return (
    <>
    <div className='d-flex align-items-center justify-content-center'>
        <nav aria-label="Page navigation" className='p-3 border-1'>
            <ul className='pagination'>
            <button className="page-item border-0 bg-transparent me-4" disabled={currentPage === 1} onClick={()=>{setCurrentPage(currentPage-1)}}><i className="fa-solid fa-circle-chevron-left txt-xxlg"></i></button>
                    {pageNumbers?.map((number)=>{
                        return  <div>
                                    <button key={number} className="rounded-pill border-0 bg-transparent text-black px-2 m-2 page-item" onClick={()=>{
                                        setCurrentPage(number)
                                    }}>
                                        {number}
                                    </button>
                                </div>
                    })}
                    
                    
            <button className="page-item border-0 bg-transparent ms-4" disabled={currentPage === Math.ceil(totalInquiry / inquiryPerPage)} onClick={()=>{setCurrentPage(currentPage+1)}}><i className="fa-solid fa-circle-chevron-right txt-xxlg"></i></button>
            </ul>
        </nav>
    </div>
    </>
  )
}

export default Pagination