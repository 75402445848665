import React, { useRef } from "react";
import Slider from "react-slick";
import "../../Style/SingleTrip/TripReviews.css";

const TripReviews = ({ trip }) => {
  const arrowRef = useRef();
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 3,
          dots: true,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="container-fluid">
      <div className="video__cont">
        {trip?.testimonials?.length > 0 && (
          <>
            <h2 className="Mon_font">
              <small>Feedbacks & Testimonials</small>
            </h2>
            <div className="hr-line-head">
              <div className="hr-line m-0 mb-3 "></div>
            </div>
          </>
        )}
        <Slider className="text-center" ref={arrowRef} {...settings}>
          {trip?.testimonials?.map((item) => {
            const date = new Date(item.date)
              .toLocaleString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })
              .replace(",", "");
            return (
              <div className="review__card Mon_font ">
                {/* <div className="text-start"><i className="fa-solid fa-quote-left"></i></div> */}
                <div>
                  <img
                    style={{ borderRadius: "50%" }}
                    className=""
                    src={item?.userimage}
                    alt="img"
                  />
                </div>
                <div className="d-flex">
                  <div className="text-start">
                    <h5>{item.heading}</h5>
                    <p className="date m-0 p-0">
                      {date.split(" ")[1] +
                        " " +
                        date.split(" ")[0] +
                        " " +
                        date.split(" ")[2]}
                    </p>
                    <p className="desc text-start">{item.description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        {trip?.testimonials?.length > 4 && (
          <div>
            <button
              onClick={() => arrowRef.current.slickPrev()}
              className="arrow__left"
            >
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <button
              onClick={() => arrowRef.current.slickNext()}
              className="arrow__right"
            >
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TripReviews;
