import React, { useEffect, useState } from 'react';
import OtherLayout from '../components/Layout/OtherLayout';
import blogBG from '../images/blogs/blog.jpg';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BlogSlider from '../components/BlogDetails/BlogSlider';
import TalkToExpert from '../components/LandingPage/TalkToExpert';
import OfferAndUpdates from '../components/LandingPage/OfferAndUpdates';

const Blog = () => {
    const [blogs, setBlogs] = useState(null);
    document.title = `Tripgix - ${'Blog'}`
    const [trendingBlogs, setTreningBlogs] = useState(null);
    const [latestBlogs, setLatestBlogs] = useState(null);
    const [informationalBlogs, setInformationalBlogs] = useState(null);
    const navigate = useNavigate();

    const getAllBlog = async (elm) => {
        await axios.get(`api/blog/get-all-blog`).then((res) => {
            let allBlogs = res.data.data
            setBlogs(allBlogs.reverse())

            const trending = allBlogs?.filter((blog)=>{
                return blog.category === 'Trending Blogs'
            })
            const latest = allBlogs?.filter((blog)=>{
                return blog.category === 'Latest Blogs'
            })
            const Informational = allBlogs?.filter((blog)=>{
                return blog.category === 'Informational Blogs'
            })
            if(trending){
                setTreningBlogs(trending)
            }
            if(latest){
                setLatestBlogs(latest)
            }
            if(Informational){
                setInformationalBlogs(Informational)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        if (blogs === null) {
            getAllBlog();
        }
        // eslint-disable-next-line
    }, []);

    const aboutSubTitle = 'Discover the world with our travel hub, Find destination guides, itineraries, and inspiring stories to fuel your wanderlust and connect with fello travellersw. Lets explore together!'
    return (
        <>
        <OtherLayout background={blogBG} title="Our Blogs" extraSubTitle={aboutSubTitle}>
            <div className="container-fluid upcoming__trips px-lg-5 px-2 mt-5 mb-3">
                <div className='heading__date__button Mon_font'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <span className="category__heading ps-2">Trending Blogs</span>
                        {/* <i className="fa-solid fa-chevron-down"></i> */}
                    </div>
                    <button onClick={() => navigate(`/blog/${'Trending-Blogs'}`)} className='btn view_all'>View All</button>
                </div>

                <BlogSlider blogs={trendingBlogs} navigatation='Trending-Blogs'/>
            </div>

            <TalkToExpert />

            <div className="container-fluid upcoming__trips px-lg-5 px-2 mt-5">
                <div className='heading__date__button Mon_font'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <span className="category__heading ps-2">Latest Blogs</span>
                        {/* <i className="fa-solid fa-chevron-down"></i> */}
                    </div>
                    <button onClick={() => navigate(`/blog/${'Latest-Blogs'}`)} className='btn view_all'>View All</button>
                </div>

                <BlogSlider blogs={latestBlogs} navigatation='Latest-Blogs'/>
            </div>

            <div className="container-fluid upcoming__trips px-lg-5 px-2 my-4">
                <div className='heading__date__button Mon_font'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <span className="category__heading ps-2">Informational Blogs</span>
                        {/* <i className="fa-solid fa-chevron-down"></i> */}
                    </div>
                    <button onClick={() => navigate(`/blog/${'Informational-Blogs'}`)} className='btn view_all'>View All</button>
                </div>

                <BlogSlider blogs={informationalBlogs} navigatation='Informational-Blogs'/>
            </div>

            <OfferAndUpdates />

        </OtherLayout>
        </>
    )
}

export default Blog
