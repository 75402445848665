import React, { useEffect, useState } from 'react'
import Layout from '../../components/Admin/AdminLayout'
import AdminHeader from './component/AdminHeader'
import Multiselect from 'multiselect-react-dropdown'
import axios from 'axios'
import { message } from 'antd'

const HomeCategory = () => {
    const [loading, setLoading] = useState(null)
    const [curatedCategory, setCuratedCategory] = useState(null)
    const [top5HomeCategory, setTop5HomeCategory] = useState(null)
    const [popularCategory, setPopularCategory] = useState(null)
    const [allCategory, setAllCategory] = useState([])

    // get all category
    const getAllCategory = async () => {
        await axios.get(`api/category/get-all-category`).then((res) => {
            let categories = []
            if (res.data.data) {
                res.data?.data.forEach(category => {
                    categories.push(category.name);
                });
            }
            setAllCategory(categories)

        }).catch((err) => {
            console.log(err);
        });
    }

    // get All Selected Home Page Category
    const getSelectedHomeCategory = () => {
        axios.get(`api/homecategory/get-home-category`).then((res) => {
            setCuratedCategory(res.data.data[0]?.curatedCategory)
            setTop5HomeCategory(res.data.data[0]?.top5HomeCategory)
            setPopularCategory(res.data.data[0]?.popularCategory)
        })
    }

    useEffect(() => {
        getSelectedHomeCategory();
        getAllCategory()
        // eslint-disable-next-line
    }, [])

    const submit = (e) => {
        e.preventDefault()
        setLoading(true)
        axios({
            method: "POST",
            data: {
                id: '6441b582018b4dda06e9ca1c',
                curatedCategory: curatedCategory,
                top5HomeCategory: top5HomeCategory,
                popularCategory: popularCategory,
            },
            withCredentials: true,
            url: `api/homecategory/update-home-category`,
        }).then((res) => {
            getSelectedHomeCategory()
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            message.success(res.data.message)
        }).catch((err) => {
            setTimeout(() => {
                setLoading(false)
            }, 1500);
            console.log(err);
        });
    }
    return (
        <Layout>
            <AdminHeader title='Select Home Category' redirect='/add-category' />
            <div className="container Mon_font px-3 m-0 pb-4">
                <form className="row g-3 m-0 p-0" onSubmit={submit}>
                    <div>
                        <h3 className='mb-1 px-2 py-1 rounded'>Select Curated Category</h3>
                        <div className='rounded border border-2 p-3 mt-4' style={{ backgroundColor: '#f5f5f5' }}>
                            <label htmlFor="category" className="form-label">Select Curated Categories (Only 6 for a good UI)</label>
                            <Multiselect
                                id="category"
                                className="multiselect-wrapper"
                                isObject={false}
                                onRemove={(event) => {
                                    if (event.length === 0) {
                                        setCuratedCategory(null)
                                    }
                                    else {
                                        setCuratedCategory(event)
                                    }
                                }}
                                selectedValues={curatedCategory}
                                options={allCategory}
                                onSelect={(event) => {
                                    setCuratedCategory(event)
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <h3 className='mb-1 mt-4 px-2 py-1 rounded'>Select Category Sliders</h3>
                        <div className='rounded border border-2 p-3 mt-4' style={{ backgroundColor: '#f5f5f5' }}>
                            <label htmlFor="category" className="form-label">Select Category Sliders (For ex - upcoming-trips, weekend-trips, ladakh sliders)</label>
                            <Multiselect
                                id="category"
                                className="multiselect-wrapper"
                                isObject={false}
                                onRemove={(event) => {
                                    if (event.length === 0) {
                                        setTop5HomeCategory(null)
                                    }
                                    else {
                                        setTop5HomeCategory(event)
                                    }
                                }}
                                selectedValues={top5HomeCategory}
                                options={allCategory}
                                onSelect={(event) => {
                                    setTop5HomeCategory(event)
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <h3 className='mb-1 mt-4 px-2 py-1 rounded'>Select Popular Destination Category</h3>
                        <div className='rounded border border-2 p-3 mt-4' style={{ backgroundColor: '#f5f5f5' }}>
                            <label htmlFor="category" className="form-label">Select Category Sliders (For ex - upcoming-trips, weekend-trips, ladakh sliders)</label>
                            <Multiselect
                                id="category"
                                className="multiselect-wrapper"
                                isObject={false}
                                onRemove={(event) => {
                                    if (event.length === 0) {
                                        setPopularCategory(null)
                                    }
                                    else {
                                        setPopularCategory(event)
                                    }
                                }}
                                selectedValues={popularCategory}
                                options={allCategory}
                                onSelect={(event) => {
                                    setPopularCategory(event)
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-12 text-center mt-4'>
                        <button className='btn btn-warning w-25'>{loading ? 'Loading...' : 'Save'}</button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default HomeCategory
