import { message } from 'antd';
import axios from 'axios';
import React, { useState } from 'react'
import '../../Style/LandingPage/OfferAndUpdate.css'

const OfferAndUpdates = () => {
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({})
    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        setLoading(true)
        axios({
            method: "POST",
            data: {
                name: form.name,
                phone: form.phone,
                email: form.email,
            },
            withCredentials: true,
            url: `api/subscribe/subscribe-email`,
        }).then((res) => {
            message.success(res.data.message)
            setTimeout(() => {
                setLoading(false)
            }, 1500);
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });
    }
    return (
        <div className='Mon_font p-5 container-fluid mt-3 offer__and__updates text-center'>
            <div className="row">
                <div className="col-12">
                    <div className='mb-3 text-dark'>
                        <h6 className='text-center mx-5'>Be the first one to know all about the exciting <b>Offers</b>, Travel <b>Updates</b> and More...</h6>
                        <h3 className='text-center my-4'><b>Subscribe us now!</b></h3>
                    </div>
                    <form className="Mon_font text-start px-5 py-3" onSubmit={submitHandler}>
                        <div className="mb-3">
                            <input required placeholder='Your name' name='name' onChange={handleChange} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <input required placeholder='Phone number' name='phone' onChange={handleChange} type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <input required placeholder='Email' name='email' onChange={handleChange} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className='text-center my-2'>
                            <button type='submit'>{loading ? 'Loading...' : 'Submit'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default OfferAndUpdates
